import React from 'react';
import { Link, NavLink } from "react-router-dom";

export interface INavigationProps {
  open: boolean;
  handleOpen: (flag: boolean) => void;
};

export class Menu extends React.Component<INavigationProps, {}> {
    static defaultProps = {
      handleOpen: (flag: boolean) => {}
    }

    render() {
      return (
      <nav className={`menu ${this.props.open ? "menu-open last-content" : ""}`} role="navigation">
        <div className="problematik">
          <NavLink className="tagline" to="/wahrnehmungsproblematik">Problematik</NavLink>
          <Link to="/wahrnehmungsproblematik#wahrnehmung">Wahrnehmung</Link>
          <Link to="/wahrnehmungsproblematik#wahrnehmungsprobleme">Wahrnehmungsprobleme</Link>
        </div>
        
        <div className="affolter-model">
          <NavLink className="tagline" to="/affolter-modell">Affolter-Modell&reg;</NavLink>
          <Link to="/affolter-modell">Erklärung</Link>
          <Link to="/affolter-modell#gruenderin">Gründerin</Link>
        </div>

        <div className="ueber-uns">
          <NavLink className="tagline" to="/ueber-uns">Über uns</NavLink>
          <Link to="/ueber-uns">Kontakt</Link>
          <Link to="/ueber-uns#team">Team</Link>
          <Link to="/ueber-uns#forschungsarbeit">Forschungsarbeit</Link>
        </div>

        <div className="angebot">
          <NavLink className="tagline" to="/angebot">Unser Angebot</NavLink>
          <Link to="/angebot/abklaerung">Abklärung</Link>
          <Link to="/angebot/therapie">Therapie</Link>
          <Link to="/angebot/beratung">Beratung</Link>
          <Link to="/angebot/kurse">Kurse</Link>
        </div>

        {/*<div className="suche">
          <NavLink  className="tagline" to="/suche">Suche</NavLink>
      </div>*/}
      </nav>
      );
    };
}