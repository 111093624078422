export class AnnouncementDataProvider {
    private fetchNextAnnouncement?: Promise<IAnnouncementData|undefined>;

    public async getNextAnnouncement() : Promise<IAnnouncementBubbleProps|undefined>
    {
        if(!this.fetchNextAnnouncement)
        {
            this.fetchNextAnnouncement = fetch("/api/announcements/next").then(this.handleResponse);
        }

        return this.convertData(await this.fetchNextAnnouncement);
    }

    private async handleResponse(response: Response) : Promise<IAnnouncementData|undefined>
    {
        if(response.status === 204) return undefined;

        return response.json();
    }

    private convertData(data: IAnnouncementData|undefined) : IAnnouncementBubbleProps|undefined
    {
        if(data === undefined) return undefined;

        return {
            title: data.caption,
            link: data.link,
            date: new Date(data.expirationDate as unknown as string)
        }
    }
};

interface IAnnouncementData {
    id: number;
    caption: string;
    link: string;
    expirationDate: Date;
}

export interface IAnnouncementBubbleProps {
    title: string,
    date: Date,
    link: string
  }