import React from 'react';
import { Link } from 'react-router-dom';

import footerSmall from './images/3_Goggel/Mobile/Goggel_168x146.png';
import footerTablet from './images/3_Goggel/Tablet/Goggel_348x300.png';
import footerDesktop from './images/3_Goggel/Desktop/Goggel_508x438.png';

export class Footer extends React.Component {
    render() {
      return (
        <footer className="footer">
          <picture className="footer-logo">
            <source media="(min-width: 1140px)" srcSet={footerDesktop} />
            <source media="(min-width: 720px)" srcSet={footerTablet} />
            <img src={footerSmall} alt="" />
          </picture>
          <p className="footer-slogan">Das Zusammenspiel von H&ouml;ren, Sehen, Sp&uuml;ren, Denken.</p>
          <div className="footer-navigation-container">
            <div className="footer-navigation footer-navigation-1">
              <p className="tagline">Service</p>
              <Link className="btn btn-internal" to="/inside">Aktuell</Link>
              <Link className="btn btn-internal" to="/inside#spenden">Spenden</Link>
              <Link className="btn btn-internal" to="/inside#literatur">Literatur</Link>          
            </div>
            <div className="footer-navigation footer-navigation-2">
              <p className="tagline">Kontakt</p>
              <address itemScope itemType="https://schema.org/Organization">
                <meta itemProp="name" content="Stiftung wahrnehmung.ch" />
                wahrnehmung.ch<br/>
                <span itemProp="streetAddress">Florastrasse 14</span><br/>
                <span itemProp="postalCode">CH-9000</span>&nbsp;<span itemProp="addressLocality">St.&nbsp;Gallen</span><br/>
                <a href="tel:+41712220234" itemProp="telephone">+41 (0)71 222 02 34</a><br/>
                <a href="mailto:info@wahrnehmung.ch" itemProp="email">info@wahrnehmung.ch</a>
              </address>
              <p className="copyright">&copy;{(new Date().getFullYear())}. <Link to="/inside#impressum">Impressum</Link></p>
            </div>
            <div className="footer-navigation footer-navigation-3">
              <p className="tagline item-1">Newsletter</p>
              <p className="item-2">Mit unseren News sind Sie immer auf dem aktuellen Stand.</p>
              <div className="item-3">
                <Link className="btn btn-internal" to="/inside#newsletter">Abonnieren</Link>
              </div>
            </div>
          </div>  
      </footer>
      );
    }
  }