import React from 'react';


export class Upload extends React.Component {
  render() {
    return (
      <span className="svg-container">
        <svg width="39" height="15">{/*viewBox="0 0 39 15"*/}
          <line fill="none" strokeMiterlimit="10" x1="19.5" y1="14.5" x2="19.5" y2="7.18" />
          <polygon points="24.49 8.63 19.5 0 14.51 8.63 24.49 8.63" />
          <line fill="none" strokeMiterlimit="10" y1="14.5" x2="39" y2="14.5" />
        </svg>
      </span>
    );
  }
}
