import { FunctionComponent, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import CookieConsent, { getCookieConsentValue, Cookies } from 'react-cookie-consent';

const GoogleAnalytics: FunctionComponent = (props: any) => {
  let location = useLocation(); 
    
  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);
  
  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
  }, [location]);

  const handleAcceptCookie = () => {
      ReactGA.initialize("G-3LPQJYLTTH");
      ReactGA.set({appName: process.env.REACT_APP_NAME});
      ReactGA.set({app_name: process.env.REACT_APP_NAME});
      ReactGA.set({appVersion: process.env.REACT_APP_VERSION});
      ReactGA.set({app_version: process.env.REACT_APP_VERSION});
  };
  
  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_ga_3LPQJYLTTH");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  return <CookieConsent 
            location='bottom'
            overlay={false}
            enableDeclineButton={true}
            buttonText="OK"
            buttonClasses="btn btn-internal"
            declineButtonClasses="btn btn-internal btn-secondary"
            declineButtonText="Ablehnen"
            onAccept={handleAcceptCookie}
            onDecline={handleDeclineCookie}
            containerClasses="cookie-consent-container">
              Diese Website verwendet Cookies, um die Benutzerfreundlichkeit zu erhöhen.
          </CookieConsent>;
}

export default GoogleAnalytics;