import React from 'react';

export class ArrowRight extends React.Component {
    render() {
      return (
        <span className="svg-container">
            <svg width="39" height="10" viewBox="0 0 39 9.97" >
                <line fill="none" strokeMiterlimit="10" y1="4.99" x2="31.82" y2="4.99"/>
                <polygon points="30.36 9.97 39 4.99 30.36 0 30.36 9.97"/>
            </svg>
        </span>
      )
    }
};