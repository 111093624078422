import React from 'react';

export class Telephone extends React.Component {
    render() {
      return (
        <div className="svg-container">
          <svg width="45" height="45" viewBox="0 0 45 45" >
            <path fill="none" strokeWidth="3px" strokeMiterlimit="10" d="M13.67,17.59l1.42-3.92a4.78,4.78,0,0,0,.29-2.77L13.05,1.68,1.58,3.33A42.18,42.18,0,0,0,13.71,30.47,41.74,41.74,0,0,0,41.29,42.62l1.23-12-8.57-2a4.64,4.64,0,0,0-2.89.35l-4.29,1.85A39.79,39.79,0,0,1,19.4,25,38.62,38.62,0,0,1,13.67,17.59Z"/>
          </svg>
        </div>
      )
    }
};