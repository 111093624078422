import React, { DetailsHTMLAttributes, FunctionComponent, useEffect } from 'react';
import './App.scss';
import {
   BrowserRouter as Router,
   Switch,
   Route,
   useLocation,
   useHistory
} from "react-router-dom";

import { Footer, Header, AffolterModellPage } from './main';
import { ProblematikPage, StartPage, UeberUnsPage, AngebotPage, InsidePage, NewsletterValidationPage, NewsletterUnsubscriptionPage, NewsletterErrorPage } from './main/ContentPage';
import { default as GoogleAnalytics } from './main/GoogleAnalytics';
import { Helmet } from 'react-helmet';

interface IHistoryProps {
  onHistoryChanged: () => void;
}

const HistoryChangedMenuHandler: FunctionComponent<IHistoryProps> = (props: IHistoryProps) => {
  let history = useHistory();
  let {pathname, hash} = useLocation();  

  useEffect(() => {
    history.listen(() => {
        props.onHistoryChanged();      
  });

  // if not a hash link, scroll to top
  if (hash === '') {
    window.scrollTo(0, 0);
  }
  // else scroll to id
  else {
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);

      if (element) {
        if(element.nodeName === "DETAILS"){
          (element as unknown as DetailsHTMLAttributes<{}>).open = true;
        }

        element.scrollIntoView({
          // optional params
          behavior: 'smooth',
          block: 'start'
      });
      }
    }, 100);
  }
}, [history, props, pathname, hash]);

  return null;
}

interface IHeaderState {
  open: boolean;
}

export default class App extends React.Component<{}, IHeaderState> {

  constructor(props: any) {
    super(props);

    this.state = {
      open: false
    }
  }

  onHandleOpen(flag: boolean)
  {
    this.setState({open: flag});
  }

  render(){
    return (
      <Router>
        <HistoryChangedMenuHandler onHistoryChanged={() => {
          this.onHandleOpen(false);
        }
          }/>
        <div className="App">
          <Header open={this.state.open} handleOpen={this.onHandleOpen.bind(this)} />
          { this.state.open ? null : 
            <Switch>
              <Route exact path="/">
                <StartPage />
              </Route>
              <Route path="/wahrnehmungsproblematik">
                <ProblematikPage />
              </Route>
              <Route path="/affolter-modell">
                <AffolterModellPage />
              </Route>
              <Route path="/ueber-uns">
                <UeberUnsPage />
              </Route>
              <Route path="/angebot">
                <AngebotPage />
              </Route>
              <Route path="/inside/validated">
                <NewsletterValidationPage />
              </Route>
              <Route path="/inside/unsubscribed">
                <NewsletterUnsubscriptionPage />
              </Route>
              <Route path="/inside/error">
                <NewsletterErrorPage />
              </Route>
              <Route path="/inside">
                <InsidePage />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
            }          
          <Footer />
        </div>
        <GoogleAnalytics />
      </Router>
    );
  };
}

export class NotFound extends  React.Component {
  render(){
    return [
      <Helmet>
        <title>404 - Seite nicht gefunden.</title>
      </Helmet>,
      <div className="page-content">
        <h1>Seite nicht gefunden.</h1>
        <p>Die Webseite wahrnehmung.ch wurde erneuert. Es ist nicht auszuschliessen, dass dadurch der Zugriff auf gewisse Seiten nicht mehr möglich ist.<br/>Falls Sie etwas Bestimmtes suchen, hilft Ihnen das Sekretariat der Stiftung&nbsp;wahrnehmung.ch gerne weiter: <a href="tel:+41712220234">+41&nbsp;(0)71&nbsp;222&nbsp;02&nbsp;34</a>, <a href="mailto:info@wahrnehmung.ch">info@wahrnehmung.ch</a>.</p>
      </div>
    ];
  }
}