import React from 'react';

export class Download extends React.Component {
    render() {
      return (
        <span className="svg-container">
          <svg width="39" height="15" >{/*viewBox="0 0 39 15"*/}
            <line fill="none" strokeMiterlimit="10" x1="19.5" x2="19.5" y2="7.32"/>
            <polygon points="14.51 5.87 19.5 14.5 24.49 5.87 14.51 5.87"/>
            <line fill="none" strokeMiterlimit="10" y1="14.5" x2="39" y2="14.5"/>
          </svg>
        </span>
      )
    }
};