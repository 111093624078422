import React from 'react';
import { INavigationProps } from './Menu';

export class Burger extends React.Component<INavigationProps, {}> {
    render() {
      return (
        <button className={`burger ${this.props.open ? "burger-open" : ""}`} onClick={() => this.props.handleOpen(!this.props.open)} aria-label={ this.props.open ? "Close" : "Navigation"} aria-haspopup="menu">
            <div aria-hidden="true" />
            <div aria-hidden="true" />
            <div aria-hidden="true" />
        </button>
      );
    };
}