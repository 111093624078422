import React from 'react';

export class Mail extends React.Component {
    render() {
      return (
        <div className="svg-container">
          <svg width="45" height="45" viewBox="0 -5 45 40">
            <path strokeMiterlimit="10" strokeWidth="3px" fill="none" d="M35.32,31.76H8.62A7.12,7.12,0,0,1,1.5,24.64V1.5H42.44V24.64A7.12,7.12,0,0,1,35.32,31.76Z"/>
            <polyline strokeMiterlimit="10" strokeWidth="3px" fill="none" points="6.21 6.8 21.97 21.08 37.82 6.78"/>
          </svg>
        </div>      
      )
    }
};