import React, { Fragment } from 'react';
import { Burger, Menu, INavigationProps } from './navigation';
import { Link, Route, Switch } from "react-router-dom";
import ReactGA from 'react-ga4';

import { Download as DownloadIcon, Upload as UploadIcon } from '../main/icons';

import logo from './logo.svg';
import { ExternalLink, KursItem } from './ContentPage';

import pdfAGBKurse from './pdfs/W_AGB_Kurse.pdf';
import { CourseDataProvider, IKursItemProps } from './CourseDataProvider';


export class Header extends React.Component<INavigationProps, {}> {
    render() {
      return (
        <header className="page-header">
            <Link to="/" className="logo">
                <img src={logo} alt="" />
                {this.props.open ? null : <span>wahrnehmung.ch</span> }
            </Link>
            <Burger open={this.props.open} handleOpen={this.props.handleOpen} />
            <Switch>
              <Route path="/angebot/kurse/:id/anmeldung-:title" render={props => <ModalDialog open={this.props.open} handleOpen={this.props.handleOpen} id={props.match.params.id} {...props} />} />               
              <Route path="*">
                <Menu open={this.props.open} />
              </Route>
            </Switch>            
        </header>
      );
    }
}

interface IModalDialogProps extends INavigationProps {
  id: string;
}

interface IModalDialogState {
  item?: IKursItemProps;
  name:string;
  firstName: string;
  jobTitle: string;
  address: string;
  place: string;
  phone: string;
  email: string;

  invoiceEmployer: "yes" | "no";

  institutionName: string;
  institutionAddress: string;
  institutionPlace: string;
  institutionPhone: string;
  institutionEmail: string;

  certificate: File | null;
  confirmation: File | null;

  kursbestimmungen: boolean;

  submitting: boolean;
  error: boolean;
  sent: boolean;
}

export class ModalDialog extends React.Component<IModalDialogProps, IModalDialogState> {
  private form: React.RefObject<HTMLFormElement>;
  private dataProvider: CourseDataProvider = new CourseDataProvider();

  constructor(props: any) {
    super(props);

    this.state = {
      item: undefined,
      name: "",
      firstName: "",
      jobTitle: "",
      address: "",
      place: "",
      phone: "",
      email: "",

      invoiceEmployer: "yes",

      institutionName: "",
      institutionAddress: "",
      institutionPlace: "",
      institutionPhone: "",
      institutionEmail: "",

      certificate: null,
      confirmation: null,

      kursbestimmungen: false,

      submitting: false,
      error: false,
      sent: false
    };

    this.form = React.createRef();
  }

  componentDidMount()
  {
    this.props.handleOpen(true);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKursbestaetigungChanged = this.handleKursbestaetigungChanged.bind(this);
    this.handleBerufsurkundeChanged = this.handleBerufsurkundeChanged.bind(this);

    this.dataProvider.getCourse(parseInt(this.props.id)).then(data => 
      {
        delete(data.anmeldung);

        this.setState({
        item: data
      })});
  }

  componentDidUpdate()
  {
    if(!this.props.open){
      (this.props as any).history.goBack();
    }
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    console.log(name, value);

    this.setState({
      [name]: value
    } as unknown as IModalDialogState);
  }

  handleSubmit(event: any) {
    if(this.state.submitting) return;

    this.setState({submitting: true});

    if(this.form !== null && this.form !== undefined) {
      var formData = new FormData(this.form.current as HTMLFormElement);
      fetch('/api/course/'+this.props.id+"/subscribe", {method: "POST", body: formData })
      .then(response => {
        if(response.ok){
          this.setState({sent: true, submitting: false});

          window.scrollTo(0, 0);

          ReactGA.event({
            category: 'Course',
            action: 'Subscribed'
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        this.setState({error: true, submitting: false});

        ReactGA.event("exception", 
        {
          description: 'Could not subscribe course. ' + error.message,
          fatal: true
        });
      });
    }

    event.preventDefault();
  }

  handleKursbestaetigungChanged(files: FileList | null | undefined)
  {
    if(!files || files.length < 1) return

    this.setState({
      confirmation: files[0]
    });
  }

  handleBerufsurkundeChanged(files: FileList | null | undefined)
  {
    if(!files || files.length < 1) return

    this.setState({
      certificate: files[0]
    });
  }

  render() {
    return (
      <div className={`modal-dialog ${this.props.open ? "modal-open last-content" : ""}`}>
        { this.state.sent ? <h1>Vielen Dank für Ihre Anmeldung.</h1> : <h1>Kursanmeldung.</h1> }

       {this.state.item && <KursItem {...this.state.item} /> }

        { this.state.sent ? <section><p className="teaser">Wir melden uns umgehend bei Ihnen.</p><button className="btn btn-internal">Zurück</button></section> :  <form encType="multipart/form-data" method="post" autoComplete="on" onSubmit={this.handleSubmit} ref={this.form}>
          <fieldset>
            <legend>Adresse privat</legend>
            <div>
              <input type="text" id="name" name="name" value={this.state.name} onChange={this.handleInputChange} placeholder="Name*" required />
              {/*<label htmlFor="name">Name</label>*/}
            </div>
            <div>
              <input type="text" id="first_name" name="firstName" value={this.state.firstName} onChange={this.handleInputChange} placeholder="Vorname*" required/>
              {/*<label htmlFor="first_name">Vorname</label>*/}
            </div>
            <div>
              <input type="text" id="jobTitle" name="jobTitle" value={this.state.jobTitle} onChange={this.handleInputChange} placeholder="Berufsbezeichnung" />
              {/*<label htmlFor="jobTitle">Berufsbezeichnung</label>*/}
            </div>
            <div>
              <input type="text" id="address" name="address" value={this.state.address} onChange={this.handleInputChange} placeholder="Adresse*" required/>
              {/*<label htmlFor="address">Adresse</label>*/}
            </div>
            <div>
              <input type="text" id="place" name="place" value={this.state.place} onChange={this.handleInputChange} placeholder="PLZ &amp; Ort*" required/>
              {/*<label htmlFor="place">PLZ &amp; Ort</label>*/}
            </div>
            <div>
              <input type="tel" id="phone" name="phone" value={this.state.phone} onChange={this.handleInputChange} placeholder="Telefon*" required/>
              {/*<label htmlFor="phone">Telefon</label>*/}
            </div>
            <div>
              <input type="email" id="email" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder="E-Mail*" required/>
              {/*<label htmlFor="email">E-Mail</label>*/}
            </div>
          </fieldset>
          <fieldset disabled={this.state.invoiceEmployer === "no"}>
            <legend>Adresse Arbeitgerber:in</legend>
            <div>
              <input type="text" id="institutionName" name="institutionName" value={this.state.institutionName} onChange={this.handleInputChange} placeholder="Name der Einrichtung*" required/>
            </div>
            <div>
              <input type="text" id="institutionAddress" name="institutionAddress" value={this.state.institutionAddress} onChange={this.handleInputChange} placeholder="Adresse*" required/>
            </div>
            <div>
              <input type="text" id="institutionPlace" name="institutionPlace" value={this.state.institutionPlace} onChange={this.handleInputChange} placeholder="PLZ &amp; Ort*" required/>
            </div>
            <div>
              <input type="tel" id="institutionPhone" name="institutionPhone" value={this.state.institutionPhone} onChange={this.handleInputChange} placeholder="Telefon*" required/>
            </div>
            <div>
              <input type="email" id="institutionEmail" name="institutionEmail" value={this.state.institutionEmail} onChange={this.handleInputChange} placeholder="E-Mail*" required/>
            </div>
          </fieldset>
          <fieldset>
            <div className="input-group">
              <label>Rechnung an den Arbeitgeber:</label>
              <div>
                <input type="radio" id="rechnung_arbeitgeber_yes" name="invoiceEmployer" value="yes" checked={this.state.invoiceEmployer === "yes"} onChange={this.handleInputChange} defaultChecked />
                <label htmlFor="rechnung_arbeitgeber_yes">Ja</label>
              </div>
              <div>
                <input type="radio" id="rechnung_arbeitgeber_no" name="invoiceEmployer" checked={this.state.invoiceEmployer === "no"} onChange={this.handleInputChange} value="no" />
                <label htmlFor="rechnung_arbeitgeber_no">Nein</label>
              </div>
            </div>
            { this.state.item !== undefined && this.state.item.provideCertificate ? <Fragment>
              <label>Kopie Berufsurkunde*:</label>
              <FileInput name="certificate" onChange={this.handleBerufsurkundeChanged} required />
            </Fragment> : null }
            { this.state.item !== undefined && this.state.item.provideConfirmation ? <Fragment>
              <label>Kursbestätigung vorhergehendes Modul*:</label>
              <FileInput name="confirmation" onChange={this.handleKursbestaetigungChanged} required />
            </Fragment> : null }
            <textarea name="message" placeholder="Bemerkung"></textarea>
            <div className="input-group">
              <label>Falls der Kurs ausgebucht ist, möchten ich auf die Warteliste gesetzt werden:</label>
              <div>
                <input type="radio" id="warteliste_yes" name="waitingList" value="yes" defaultChecked />
                <label htmlFor="warteliste_yes">Ja</label>
              </div>
              <div>
                <input type="radio" id="warteliste_no" name="waitingList" value="no" />
                <label htmlFor="warteliste_no">Nein</label>
              </div>
            </div>
            <div className="input-group">
              <label htmlFor="kursbestimmungen">Ja, ich akzeptiere die Kursbestimmungen (AGB).</label>
              <div>
                <input type="checkbox" id="kursbestimmungen" name="kursbestimmungen" checked={this.state.kursbestimmungen} onChange={this.handleInputChange} required />
                <ExternalLink to={pdfAGBKurse}><DownloadIcon/>PDF AGB</ExternalLink>
              </div>
            </div>            
            <button className="btn btn-internal" type="submit" disabled={this.state.submitting}>Senden</button>
            {this.state.submitting && <p>Wird gesendet. Bitte warten.</p>}
          </fieldset>
        </form> }
      </div>      
    )
  }
}

interface IFileInputProps {
  name: string;
  label: string;
  multiple?: boolean;
  multipleLabel: string;
  required?: boolean;

  onChange?: (fileList: FileList | null | undefined) => void;
}

interface IFileInputState {
  label: string;
}

export class FileInput extends React.Component<IFileInputProps, IFileInputState> {
  static defaultProps = {
    multiple: false,
    label: "Upload",
    multipleLabel: "{count} Files"
  }

  private ref: React.RefObject<HTMLInputElement>;

  constructor(props: any)
  {
      super(props);
      this.onFileChanged = this.onFileChanged.bind(this);

      this.state = {
        label: this.props.label
      };

      this.ref = React.createRef();
  }

  componentDidMount()
  {
    /*this.setState({
      label: this.props.label
    });*/
  }

  onFileChanged(event: any)
  {
    var input = event.target;

    var fileName = '';
      if( input.files && input.files.length > 1 )
        fileName = ( this.props.multipleLabel || '' ).replace( '{count}', input.files.length );
      else
        fileName = input.value.split( '\\' ).pop();
    
    this.setState({
      label: fileName ? fileName : this.props.label
    });

    if(this.props.onChange) this.props.onChange(this.ref?.current?.files);
  }

  render(){
    return (
      <div>
        <input type="file" id={this.props.name} name={this.props.name} onChange={ e => this.onFileChanged(e)} multiple={this.props.multiple} ref={this.ref} required={this.props.required} />
        <label htmlFor={this.props.name} className="btn btn-external"><UploadIcon /><div>{this.state.label}</div></label>
      </div>
    )
  }  
}